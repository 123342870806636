import * as React from 'react';
import {useEventCallback, useObservable} from "rxjs-hooks";
import {checkIt, sharedCheckList, unCheckIt} from "../services/rpcs";
import {ChecklistType} from "../react-app-env";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import {Button, Grid, IconButton, ListSubheader, Paper} from "@mui/material";
import {useEffect, useMemo, useRef, useState} from "react";
import {Observable} from "rxjs/Observable";
import MoreIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";
import DismissMenuItem from "./DismissMenuItem";

export default function CheckLists() {

    const lists = useObservable<ChecklistType[] | []>(() => sharedCheckList, []);

    if (lists.length < 1) {
        return null;
    }

    return (
        <>
            <List dense sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                {lists.map(list => <CheckList list={list} key={list.id}/>)}
            </List>
        </>
    )
}


export function CheckList({list}) {

    const showDismiss = useMemo(() => list?.checklist_lines?.find(i => !i.checked), [list?.checklist_lines]);

    return (<Paper key={list?.id} sx={{mb: 1, pb: 1}} variant={'outlined'}>
        <ListSubheader>
            <Grid container direction={'column'} spacing={0}>
                <Grid container direction={'row'} justifyContent="space-between">
                    <Grid item>{list?.name}</Grid>
                    <Grid item>
                        <ChecklistMenu list={list}/>
                    </Grid>
                </Grid>
                {/*<div>second row?</div>*/}
            </Grid>

        </ListSubheader>
        {list?.checklist_lines.map((value) => {
            return <ChecklistItem value={value} key={value.title + value.id}/>
        })}

        {!showDismiss &&
        <DismissMenuItem list={list} sx={{m: 2, p: 0}}>
            <Button variant={'outlined'} fullWidth>
                Complete Check List
            </Button>
        </DismissMenuItem>}
    </Paper>)
}

function ChecklistItem({value: item}) {

    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [handleCheck] = useEventCallback((event$): Observable<any> =>
        event$
            .do(() => setDisabled(true))
            .debounceTime(100)
            .switchMap(({id, checked}) => checkIt(id)
                .catch(e => {
                    setDisabled(false);
                    setChecked(!checked);
                    return Observable.empty();
                }))
            .do(() => setDisabled(false))
    );

    const [handleUnCheck] = useEventCallback((event$): Observable<any> =>
        event$
            .do(() => setDisabled(true))
            .debounceTime(100)
            .switchMap(({id}) => unCheckIt(id)
                .catch(e => {
                    setDisabled(false);
                    setChecked(!checked);
                    return Observable.empty();
                }))
            .do(() => setDisabled(false))
    );

    const handleToggle = (c) => {
        c ? handleUnCheck(item) : handleCheck(item);
        setChecked(!c);
    };

    useEffect(() => {
        setChecked(item.checked);
    }, [item.checked])


    const labelId = `checkbox-list-secondary-label-${item.id}`;
    return (
        <ListItem
            secondaryAction={
                <Checkbox
                    edge="end"
                    onChange={() => handleToggle(checked)}
                    checked={checked}
                    inputProps={{'aria-labelledby': labelId}}
                    disabled={disabled}
                />
            }
            disablePadding
        >
            <ListItemButton>
                <ListItemAvatar>
                    <Avatar
                        alt={`Avatar n°${item + 1}`}
                        src={`/static/images/avatar/${item + 1}.jpg`}
                    />
                </ListItemAvatar>
                <ListItemText id={labelId} primary={item.title}
                              secondary={item.description}/>
            </ListItemButton>
        </ListItem>
    );

}

export function ChecklistMenu({list}) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton
                sx={{mr: -1}}
                size="small"
                aria-label="show more"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                <MoreIcon/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <DismissMenuItem list={list} onClick={handleClose}>Submit without completing</DismissMenuItem>
            </Menu>
        </>
    );
}
