import * as React from 'react';
import {useProgress} from "./hooks/useProgress";
import {Box, Container, LinearProgress} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Copyright from "./componants/Copywrite";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import BottomTabs from "./componants/BottomTabs";
import TopBar from "./componants/TopBar";
import CheckLists from "./componants/CheckLists";
import AssetStatus from "./componants/AssetStatus";

export default function App() {
    const showProgress = useProgress();

    return (
        <Container component="main" maxWidth="xs">
            <TopBar/>
            <CssBaseline/>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <div style={{
                    position: 'fixed',
                    // zIndex: 2000,
                    width: '100%',
                    top: 0
                }}>
                    {showProgress && <LinearProgress variant={'indeterminate'}/>}
                </div>
                <Typography component="h1" variant="h5" sx={{verticalAlign: 'middle'}}>
                    <LockOutlinedIcon sx={{verticalAlign: 'middle', mb: '3px', mr: 1, transform: 'rotateY(180deg)'}}/>
                    DriveSafely
                </Typography>
                <AssetStatus/>
                <CheckLists/>
            </Box>
            <Copyright sx={{mt: 5}}/>
            <BottomTabs/>
        </Container>
    )
}

