import {wamp} from './wamp';
import {anonWamp} from "../authentication/anonWamp";

import {endProgress, startProgress} from "../hooks/useProgress";
import {setErrorAlert} from "../hooks/useErrors";
import {Observable} from "rxjs/Observable";
import {ChecklistLineType, ChecklistType, AssetStatusType, PersonClockedInType} from "../react-app-env";
import {ReplaySubject} from "rxjs";

// Helper Functions
export const getArg0 = ({args}) => args[0];
const pwCall = (w) => (uri, args: any[] = []) => {
    startProgress();
    return w.progressiveCall(uri, [...args])
        .do({error: (e) => setErrorAlert({title: 'Connection Error', message: e.message, error: e, severity: 'error'})})
        .map(getArg0)
        .do(endProgress);
}

const pCall = pwCall(wamp);
const anonPCall = pwCall(anonWamp);

export const echoTest = (text): Observable<string> => pCall('drive.echo', [text]);
export const clockIn = ([vehicleId, location]: [string, GeolocationCoordinates]): Observable<string> => pCall('drive.clock_in', [vehicleId, location]);
export const clockOut = (location: GeolocationCoordinates = null): Observable<string> => pCall('drive.clock_out', [location]);
export const clockOutVehicle = (id): Observable<any> => pCall('drive.clock_out_asset', [id]);
export const checklist = (): Observable<ChecklistType[]> => pCall('drive.checklist', []);
export const dismissList = (listId): Observable<{ id: string }> => pCall('drive.dismiss_checklist', [listId]);

export const checkIt = (id): Observable<ChecklistLineType> => pCall('drive.check_it', [id]);
export const unCheckIt = (id): Observable<ChecklistLineType> => pCall('drive.un_check_it', [id]);
export const vehicleStatus = (): Observable<AssetStatusType[]> => pCall('drive.status', []);

// Dashboard stuff
export const clockedInPeople = () : Observable<PersonClockedInType[]> => pCall('drive.get_clocked_in', []);
export const watchMedia = () : Observable<PersonClockedInType[]> => pCall('drive.watch_media', []);

// Anon RPCs
export const getQrTarget = (uuid): Observable<string> => anonPCall('drive.get_qr_target', [uuid]);
export const sendVerificationCode = (phone): Observable<any> => anonPCall('drive.send_verification_code', [phone]);
export const getTokenRPC = ([phone, code]): Observable<any> => anonPCall('drive.get_token', [phone, code]);

// Camera
export const getSignedURL = (fileName: string): Observable<any> => pCall('drive.get_s3_put_url', [fileName]);
export const getS3Image = (mediaItemId: string): Observable<any> => pCall('drive.get_s3_get_url', [mediaItemId]);
export const saveMedia = (s3Key, mimeType, location: GeolocationCoordinates = null, title: string = null, description: string = null): Observable<any> => pCall('drive.save_media', [s3Key, mimeType, location, title, description]);

// Notes
export const addNote = (entityType, entityId, location, note, mediaItemId): Observable<any> => pCall('drive.add_note', [entityType, entityId, location, note, mediaItemId]);
export const getNotes = (entityType:string, entityId:string): Observable<any> => pCall('drive.get_notes', [entityType, entityId]);

export const sharedCheckList: ReplaySubject<ChecklistType[]> = new ReplaySubject(1);
checklist()
    .repeatWhen(a => a.delay(1000))
    .retryWhen(e => e.delay(1000)) //@todo need some better error handling
    .subscribe(sharedCheckList);

export const sharedVehicleStatus: ReplaySubject<AssetStatusType[]> = new ReplaySubject(1);
vehicleStatus()
    .repeatWhen(a => a.delay(1000))
    .retryWhen(e => e.delay(1000)) //@todo need some better error handling
    .subscribe(sharedVehicleStatus);
