import * as React from 'react';
import Button from "@mui/material/Button";
import {useObservable} from "rxjs-hooks";
import {sharedCheckList} from "../services/rpcs";
import {ButtonProps} from "@mui/material/Button/Button";
import {useState} from "react";
import {useNavigate} from "react-router";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@mui/material";
import {ChecklistType} from "../react-app-env";
import useHandleCheckOut from "../hooks/useHandleCheckOut";
import useHandleDismissAll from "../hooks/useHandleDismissAll";

export default function CheckOutButton(props: ButtonProps) {

    const navigate = useNavigate();
    const list: ChecklistType[] = useObservable(() => sharedCheckList, []);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [handleCheckOut] = useHandleCheckOut(
        () => setDisabled(true),
        () => {
            setDisabled(false);
            navigate('/', {replace: true});
            setOpen(false);
        },
        () => setDisabled(false)
    );

    const [handleDismissAll] = useHandleDismissAll(
        () => setDisabled(true),
        () => handleCheckOut(''),
        () => setDisabled(false)
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                onClick={handleClickOpen}
                variant="contained"
                color={'warning'}
                disabled={disabled}
                {...props}
            >
                Clock Out
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure that you want to clock out?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have {list.length} lists that need to be dismissed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container direction={'column'} sx={{height: 200}} justifyContent={'space-evenly'}>
                        <Button onClick={() => handleDismissAll(list)} fullWidth variant={'contained'} color={'error'}
                                disabled={disabled || list.length < 1}>Dismiss {list.length} and Clock Out</Button>
                        <Button onClick={handleCheckOut} fullWidth variant={'contained'} color={'warning'}
                                disabled={disabled}>Just Clock Out</Button>
                        <Button onClick={handleClose} autoFocus fullWidth variant={'contained'}>Close</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}
