import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {Camera, CameraType} from "react-camera-pro";
import {useEventCallback} from "rxjs-hooks";
import {Observable} from "rxjs/Observable";
import {getSignedURL, saveMedia} from "../../services/rpcs";
import {fileUpload} from "../../services/http";
import useGeolocation from "react-hook-geolocation";
import {IconButton, LinearProgress} from "@mui/material";
import PhotoCamera from '@mui/icons-material/PhotoCameraOutlined';
import {ButtonProps} from "@mui/material/Button/Button";

const Wrapper = styled.div`
  position: fixed;
  padding-top: 30px;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column-reverse;

  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }

  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

const ChangeFacingCameraButton = styled(Button)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  padding: 40px;
  &:disabled {
    opacity: 1;
    cursor: default;
  }
  @media (max-width: 400px) {
    padding: 40px 5px;
  }
`;

const ImagePreview = styled.div<{ image: string | null }>`
  width: 120px;
  height: 120px;
  ${({image}) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 400px) {
    width: 50px;
    height: 120px;
  }
`;

const CameraOverlay = ({
                           onChange = (photo) => {
                           }
                       }) => {
    const [numberOfCameras, setNumberOfCameras] = useState(0);
    const [image, setImage] = useState<string | null>(null);
    const [progress, setProgress] = useState(0);

    const camera = useRef<CameraType>(null);
    const geolocation = useGeolocation({
        enableHighAccuracy: true,
        maximumAge: 15000,
        timeout: 12000
    });

    const [saveImage] = useEventCallback((event$) => event$
        .do(() => setProgress(10))
        .switchMap(([base64, location]) => {
            const fileName = Date.now().toString() + '.jpeg'
            const file = dataURLtoFile(base64, fileName)
            return getSignedURL(file.name)
                .catch(e => {
                    console.log(e);
                    return Observable.empty();
                })
                .map(r => [r.url, file, r.key, location]);
        })
        .switchMap(([url, file, key, location]: [string, File, string, GeolocationCoordinates]) => {
            return Observable
                .from(fileUpload(url, file, (e: ProgressEvent) => setProgress((e.loaded / e.total) * 100)))
                .catch(e => {
                    console.log(e)
                    setProgress(0);
                    return Observable.empty();
                })
                .flatMapTo(saveMedia(key, file.type, location))
                .do(() => setProgress(0));

        }), null);

    const handleImage = (i) => {
        setImage(i);
        onChange(i);
        saveImage([i, geolocation]);
    }

    return (
        <Wrapper>
            <Camera
                ref={camera}
                aspectRatio={4 / 4}
                numberOfCamerasCallback={setNumberOfCameras}
                facingMode={'environment'}
                errorMessages={{
                    noCameraAccessible: 'No Camera',
                    permissionDenied: 'permission denied',
                    switchCamera: 'switch camera',
                    canvas: 'canvas'
                }}
            />
            {!!progress && <LinearProgress variant="determinate" value={progress}/>}
            <Control>
                <ImagePreview image={image}/>
                <TakePhotoButton
                    onClick={() => {
                        if (camera.current) {
                            const photo = camera.current.takePhoto();
                            // console.log(photo);
                            handleImage(photo);
                        }
                    }}
                />
                <ChangeFacingCameraButton
                    disabled={numberOfCameras <= 1}
                    onClick={() => {
                        if (camera.current) {
                            const result = camera.current.switchCamera();
                            console.log(result);
                        }
                    }}
                />
            </Control>
        </Wrapper>
    );
};

export default CameraOverlay;

function TakePhotoButton(props: ButtonProps) {
    return <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        style={{
            border: 'solid 4px white',
            borderRadius: '50%',
            width: 80,
            height: 80
        }}
        {...props}
    >
        <PhotoCamera style={{
            color: 'white',
            height: 50,
            width: 50
        }}/>
    </IconButton>
}

const dataURLtoFile = (dataURL, filename): File => {
    const arr = dataURL.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, {type: mime})
}
