import CssBaseline from "@mui/material/CssBaseline";
import {Box, Button, GlobalStyles, Grid, Paper, SwipeableDrawer} from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";
import {grey} from "@mui/material/colors";

const Root = styled('div')(({theme}) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({theme}) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const drawerBleeding = 25;

export default function BottomDrawer({
                                         open = false,
                                         onOpen,
                                         onClose,
                                         element,
                                         swipeToOpen = false,
                                         keepMounted = false,
                                         closeLabel = 'Cancel',
                                         rightElement = null
                                     }) {


    // const [setOpen, open] = useEventCallback<boolean, boolean, [boolean]>((event$, _, input$) => {
    //     return event$.merge(input$.map(([o]) => o))
    // }, false, [o])

    return <Root>
        <CssBaseline/>
        <GlobalStyles
            styles={{
                '.MuiDrawer-root > .MuiPaper-root': {
                    height: `calc(95% - ${drawerBleeding}px)`,
                    overflow: 'visible',
                },
            }}
        />
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={!swipeToOpen}
            ModalProps={{keepMounted}}
        >
            <StyledBox
                sx={{
                    position: 'absolute',
                    top: -drawerBleeding,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    visibility: 'visible',
                    right: 0,
                    left: 0,
                }}
            >
                <Puller/>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button onClick={() => onClose(true)}>{closeLabel}</Button>
                    {rightElement}
                </Grid>
            </StyledBox>

            {element}
        </SwipeableDrawer>
    </Root>
}
