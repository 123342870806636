import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {useState} from "react";
import {useAuth} from "../authentication/AuthProvider";
import QrButton from "./qr-scanner/QrButton";
import CameraButton from "./camera/CameraButton";
import {Link} from "react-router-dom";


export default function TopBar() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
    const {signOut} = useAuth();

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLogout = () => {
        handleMenuClose();
        signOut();
    }

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose} disabled={true}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}  component={Link} to={'/debug'}>Debug</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed">
                <Toolbar variant={'dense'}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="home"
                        sx={{mr: 2}}
                        component={Link}
                        to={'/'}
                    >
                        <MenuIcon/>
                    </IconButton>
                    {/*<Typography*/}
                    {/*    variant="h6"*/}
                    {/*    noWrap*/}
                    {/*    component="div"*/}
                    {/*    sx={{ display: { xs: 'none', sm: 'block' } }}*/}
                    {/*>*/}
                    {/*    MUI*/}
                    {/*</Typography>*/}
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: 'flex', md: 'flex'}}}>
                        {/*<QrButton color={'inherit'}/>*/}
                        {/*<CameraButton color={'inherit'}/>*/}
                        {/*<IconButton*/}
                        {/*    size="large"*/}
                        {/*    aria-label="show 17 new notifications"*/}
                        {/*    color="inherit"*/}
                        {/*>*/}
                        {/*    <Badge badgeContent={17} color="error">*/}
                        {/*        <NotificationsIcon/>*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                    </Box>
                    {/*<Box sx={{display: {xs: 'flex', md: 'none'}}}>*/}
                    {/*    <IconButton*/}
                    {/*        size="large"*/}
                    {/*        aria-label="show more"*/}
                    {/*        aria-controls={mobileMenuId}*/}
                    {/*        aria-haspopup="true"*/}
                    {/*        onClick={handleMobileMenuOpen}*/}
                    {/*        color="inherit"*/}
                    {/*    >*/}
                    {/*        <MoreIcon/>*/}
                    {/*    </IconButton>*/}
                    {/*</Box>*/}
                </Toolbar>
            </AppBar>
            {/*{renderMobileMenu}*/}
            {renderMenu}
        </Box>
    );
}
