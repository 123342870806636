import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import AuthProvider, {RequireAuth} from "./authentication/AuthProvider";
import CheckIn from "./pages/CheckIn";
import Login from "./authentication/Login";
import {ThemeProvider, useMediaQuery} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import Checklist from "./pages/Checklist";
import Qr from "./pages/Qr";
import Debug from "./pages/Debug";
import Stream from "./pages/Stream";

function Index() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    return (
        <React.StrictMode>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <Routes>
                            <Route path="/" element={<RequireAuth><Stream/></RequireAuth>}/>
                            <Route path="/old" element={<RequireAuth><App/></RequireAuth>}/>
                            <Route path={'/login'} element={<Login/>}/>
                            <Route path={'/checkin/:id'} element={<RequireAuth><CheckIn/></RequireAuth>}/>
                            <Route path={'/vehicle/:id'} element={<RequireAuth><CheckIn/></RequireAuth>}/>
                            <Route path={'checklist'} element={<RequireAuth><Checklist/></RequireAuth>}/>
                            <Route path={'debug'} element={<RequireAuth><Debug/></RequireAuth>}/>
                            <Route path={'/qr/:uuid'} element={<Qr/>}/>
                        </Routes>
                    </AuthProvider>
                </ThemeProvider>
            </BrowserRouter>
        </React.StrictMode>
    )
}

ReactDOM.render(<Index/>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
