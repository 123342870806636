import {Box, TextField} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router";

export default function ClockInInput() {

    const navigate = useNavigate()
    const [id, setId] = useState('');

    const handleSubmit = () => navigate(`/vehicle/${id}`);

    return <Box
        component="form"
        sx={{
            // '& > :not(style)': {m: 1, width: '25ch'},
            m: 2
        }}
        noValidate
        autoComplete="off"
        onSubmit={e => {
            e.preventDefault();
            handleSubmit();
        }}
    >
        <TextField
            id="clockin"
            label="Enter Code"
            variant="outlined"
            onChange={e => setId(e.target.value)}
            value={id}
        />
    </Box>
}
