import {useEventCallback} from "rxjs-hooks";
import {ChecklistType} from "../react-app-env";
import {dismissList} from "../services/rpcs";
import {Observable} from "rxjs/Observable";

export default function useHandleDismissAll(onStart, onComplete, onError){
    return useEventCallback((e) => e
            .do(onStart)
            .flatMap((list: ChecklistType[]) => list)
            .flatMap(({id}) => dismissList(id)
                .catch(e => {
                    onError(e);
                    //@todo need better error handling
                    return Observable.empty();
                })
            )
            .do(onComplete)
        , '');
}
