import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Copyright from "../componants/Copywrite";
import {Navigate, useNavigate, useParams} from "react-router";
import useGeolocation from 'react-hook-geolocation'
import {useEventCallback, useObservable} from "rxjs-hooks";
import {clockIn, clockOutVehicle, sharedVehicleStatus} from "../services/rpcs";
import Button from "@mui/material/Button";
import {Observable} from "rxjs/Observable";
import {Alert, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import BottomTabs from "../componants/BottomTabs";
import CheckOutButton from "../componants/CheckOutButton";
import NewTripButton from "../componants/NewTripButton";

export default function CheckIn() {
    const {id} = useParams<{ id: string }>();

    const checkInStatus = useStatus(id); // This is where we clock in... @todo make this better

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {
                    {
                        'loading': <Loading/>,
                        'geolocation_timeout': <GeolocationTimeout/>,
                        'already_clocked_in': <AlreadyCheckedIn/>,
                        'asset_already_clocked_in': <VehicleAlreadyClockedIn id={id}/>,
                        'current_asset': <CurrentVehicle id={id}/>,
                        'new_clock_in': <NewCheckIn/>,
                        'clock_in_asset_does_not_exist': <MissingVehicle/>,
                    }[checkInStatus]
                }
            </Box>
            <Copyright sx={{mt: 5}}/>
            <BottomTabs/>
        </Container>
    );
}

type Statuses =
    'geolocation_timeout'
    | 'already_clocked_in'
    | 'vehicle_already_clocked_in'
    | 'current_vehicle'
    | 'new_clock_in'
    | 'loading'
    | 'clock_in_vehicle_does_not_exist';

function useStatus(id): Statuses {
    const geolocation = useGeolocation({
        enableHighAccuracy: true,
        maximumAge: 15000,
        timeout: 12000
    });

    return useObservable((_, input$) =>
            sharedVehicleStatus
                .take(1)
                .combineLatest(input$
                    .filter(([i, g]) => !!g?.timestamp)
                    .take(1)
                    .timeout(10000)
                    .catch(e => Observable.throw(new Error('geolocation_timeout')))
                )
                .map(([status, inputs]) => [...inputs, ...status])
                .switchMap(([i, g, s]) => {
                    if (i === s?.asset_id) {
                        return Observable.of('current_vehicle');
                    }
                    return clockIn([i, g])

                        .mapTo('new_clock_in')
                })
                .distinctUntilChanged()
                .catch((er) => Observable.of(er.message))
        ,
        'loading', [id, geolocation]
    )
}

function CurrentVehicle({id}) {
    const navigate = useNavigate();


    // const [handNewTrip] =

    return (
        <Paper sx={{p: 2}}>
            <Typography variant={'body2'}>
                You're already checked into this vehicle
            </Typography>
            <Grid container direction={'column'} sx={{height: 150}} justifyContent={'space-evenly'}>
                <CheckOutButton fullWidth/>
                <NewTripButton id={id}/>
                <Button variant={'contained'}
                        color={'primary'}
                        onClick={() => navigate('/', {replace: true})}
                        fullWidth
                >Continue</Button>
            </Grid>
        </Paper>
    )
}

function NewCheckIn() {
    return (
        <Box>
            <Navigate to={'/'} replace/>
        </Box>
    )
}

function Loading() {
    return (
        <Box>
            <CircularProgress variant={'indeterminate'} size={'1.5em'}/> Searching...
        </Box>
    );
}

function AlreadyCheckedIn() {
    const navigate = useNavigate();

    return (
        <Paper sx={{p: 2}}>
            <Typography variant={'body2'}>
                You're already checked into a different vehicle. (should probably display vehicle info)
            </Typography>
            <Grid container direction={'column'} sx={{height: 150}} justifyContent={'space-evenly'}>
                <CheckOutButton fullWidth/>
                <Button variant={'contained'}
                        color={'primary'}
                        onClick={() => navigate('/', {replace: true})}
                        fullWidth
                >Okay</Button>
            </Grid>
        </Paper>
    );
}

function VehicleAlreadyClockedIn({id}) {
    const navigate = useNavigate();

    const [handleKickOut] = useEventCallback((e) =>
            e.switchMap((id) => clockOutVehicle(id).do(() => window.location.reload()))
        , '');

    return (
        <Paper sx={{p: 2}}>
            <Typography variant={'body2'}>
                Someone else is already checked into this vehicle
            </Typography>
            <Grid container direction={'column'} sx={{height: 150}} justifyContent={'space-evenly'}>
                <Button variant={'contained'}
                        color={'warning'}
                        onClick={() => handleKickOut(id)}
                        fullWidth>Kick them out</Button>
                <Button variant={'contained'}
                        color={'primary'}
                        onClick={() => navigate('/', {replace: true})}
                        fullWidth>Okay</Button>
            </Grid>
        </Paper>
    );
}

function GeolocationTimeout() {
    return (
        <Box>
            <Alert color={'error'}>Geolocation must be enabled to use this App</Alert>
        </Box>
    );
}

function MissingVehicle(){
    return (
        <Box>
            <Alert color={'error'} >The vehicle does not exist</Alert>
        </Box>
    )
}
