import * as React from 'react';
import {Box, Container, LinearProgress} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import TopBar from "../componants/TopBar";
import {useProgress} from "../hooks/useProgress";
import BottomTabs from "../componants/BottomTabs";
import MessageList from "../componants/messages/MessageList";
import {Observable} from "rxjs/Observable";
import {useObservable} from "rxjs-hooks";

const message$ = Observable.of([
    {type: 'time-clock', id: 1, pin: true},
    {type: 'checklist', checklist_id: 1, pin: true},
    {
        type: 'message',
        title: 'Test Message 1',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },
    {
        type: 'message',
        title: 'Test Message',
        text: 'This is some testing text…',
        image: '/static/images/avatar/2.jpg',
        pin: false
    },

]);

export default function Stream() {
    const showProgress = useProgress();

    const messages = useObservable(() => message$, []);


    return (
        <Container component="main" maxWidth="xs">
            <TopBar/>
            <CssBaseline/>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pb: 10
            }}>
                <div style={{
                    position: 'fixed',
                    // zIndex: 2000,
                    width: '100%',
                    top: 0
                }}>
                    {showProgress && <LinearProgress variant={'indeterminate'}/>}
                </div>

                <div style={{position: 'sticky', top: 48, zIndex: 2}}>
                    <MessageList messages={messages.filter(m => m?.pin)}/>
                </div>

                <MessageList messages={messages.filter(m => !m?.pin)}/>


            </Box>
            <BottomTabs/>
        </Container>
    )
}

