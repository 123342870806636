import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Copyright from "../componants/Copywrite";

import {useObservable} from "rxjs-hooks";
import {checklist} from "../services/rpcs";
import BottomTabs from "../componants/BottomTabs";


export default function Checklist() {

    const list = useObservable(() => checklist().retryWhen(e => e.delay(1000)));

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div>
                    Checklist
                </div>
                <pre>
                     {JSON.stringify(list, null, 2)}
                </pre>
            </Box>
            {/*<Copyright sx={{mt: 5}}/>*/}
            <BottomTabs/>
        </Container>
    );
}
