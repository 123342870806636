import {Navigate, useParams} from "react-router";
import {useObservable} from "rxjs-hooks";
import {getQrTarget} from "../services/rpcs";
import {isValidHttpUrl} from "../componants/qr-scanner/ScanCode";


//QR Code redirect ---- This will eventually be handled by the server
export default function Qr() {

    const {uuid} = useParams<{ uuid: string }>();

    const path = useObservable((_, input$) => input$
            .switchMap(([uuid]) => getQrTarget(uuid))
            .map(url => {
                const u = isValidHttpUrl(url);
                console.log(u, url);
                return u ? u.pathname : '/';
            })
        ,
        null, [uuid])

    return path && <Navigate to={path} replace/>;
}
