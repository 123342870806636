import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CameraIcon from "@mui/icons-material/CameraAlt";
import {Link, useMatch, useResolvedPath} from "react-router-dom";
import {useLocation} from "react-router";
import {AppBar, Toolbar} from "@mui/material";
import {useState} from "react";
import Camera from "./camera/Camera";
import BottomDrawer from "./BottomDrawer";
import QrCodeIcon from "@mui/icons-material/QrCodeScanner";
import HomeIcon from "@mui/icons-material/Home";
import {ScanCode} from "./qr-scanner/ScanCode";
import MainSpeedDial from "./MainSpeedDial";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";

export default function BottomTabs() {
    const location = useLocation();
    let match = useMatch({path: location.pathname, end: true});

    const [showCamera, setShowCamera] = useState(false);
    const toggleCameraDrawer = (newOpen: boolean) => () => setShowCamera(newOpen);

    const [showQr, setShowQr] = useState(false);
    const toggleQrDrawer = (newOpen: boolean) => () => setShowQr(newOpen);

    return (<div style={{paddingTop: 100}}>

            <AppBar position="fixed" color="primary" sx={{top: 'auto', bottom: 0, zIndex: 1000}}>
                <BottomNavigation
                    // showLabels
                    // value={value}
                >
                    <BottomNavigationAction label="Home" icon={<HomeIcon/>} component={Link} to={'/'}/>
                    {/*<BottomNavigationAction label="Camera" icon={<CameraIcon/>} onClick={() => setShowCamera(true)}/>*/}
                    {/*<BottomNavigationAction label="QR Code" icon={<QrCodeIcon/>} onClick={() => setShowQr(true)}/>*/}
                    {/*<BottomNavigationAction label="Debug" icon={<BugReportIcon/>} component={Link} to={'/debug'}/>*/}
                    {/*<BottomNavigationAction label="Vehicle" icon={<LocationOnIcon />} />*/}
                    <BottomNavigationAction label="Alerts" icon={ <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                    >
                        <Badge badgeContent={17} color="error">
                            <NotificationsIcon/>
                        </Badge>
                    </IconButton>} />
                </BottomNavigation>
            </AppBar>
            <BottomDrawer
                element={<ScanCode/>}
                open={showQr}
                onClose={toggleQrDrawer(false)}
                onOpen={toggleQrDrawer(true)}
            />
            <BottomDrawer
                element={<Camera/>}
                open={showCamera}
                onClose={toggleCameraDrawer(false)}
                onOpen={toggleCameraDrawer(true)}
            />
            <MainSpeedDial/>
        </div>

    );
}
