import {createContext, ReactNode, useContext, useEffect} from "react";
import {Navigate, useLocation} from "react-router";
import {ReplaySubject} from "rxjs";
import useLocalStorage from "../hooks/useLocalStorage";
import {useEventCallback} from "rxjs-hooks";
import {Observable} from "rxjs/Observable";
import {getTokenRPC} from "../services/rpcs";

interface AuthContextType {
    token: any;
    signIn: (phoneNumber: string, code: string, callback: (token: string) => void, onError: (e: Error) => void) => void;
    signOut: () => void;
}

export const authSubject = new ReplaySubject<AuthContextType>(1);

const AuthContext = createContext<AuthContextType>(null!);

export default function AuthProvider({children}: { children: ReactNode }) {

    const [token, setToken] = useLocalStorage('token', null);

    const [getToken] = useEventCallback(event$ => event$
        .switchMap(([phone, code, callback, onError]) =>
            getTokenRPC([phone, code])
                .timeout(10000)
                .catch(e => {
                    onError(e);
                    return Observable.empty();
                })
                .do(t => setToken(t))
                .do(callback)
        )
    )

    const signIn = (phoneNumber: string, code: string, callback: (token: string) => void, onError: (e: Error) => void) => {
        getToken([phoneNumber, code, callback, onError]);
    };

    const signOut = () => {
        setToken(null);
        return <Navigate to="/login" state={{from: '/'}} replace/>;
    };

    const value = {token, signIn, signOut};

    useEffect(() => {
        token && authSubject.next(value);
    }, [token])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}


export function RequireAuth({children}: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.token) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return children;
}
