import * as React from 'react';
import {AppBar, IconButton, Paper, TextField, Toolbar, useTheme} from "@mui/material";
import CameraButton from "../camera/CameraButton";
import {useEffect, useState} from "react";
import {Delete} from "@mui/icons-material";

const styles = {
    container: {
        width: '400px',
        marginTop: 15,
        padding: 10,
        height: '100%'
    },
};

export default function AddNote() {

    const [viewport, setViewport] = useState({
        "maxHeight": "100vh",
        "maxWidth": "100vw"
    })

    const updateViewport = () => {
        setViewport({
            maxHeight: window.visualViewport.height.toString(),
            maxWidth: window.visualViewport.width.toString()
        })
    }

    useEffect(() => {
        if (
            typeof window !== "undefined"
            && typeof window.visualViewport !== "undefined"
        ) {
            updateViewport()
            window.visualViewport.addEventListener("resize", updateViewport)
            return () => window.visualViewport.removeEventListener("resize", updateViewport)
        }
    }, [])

    const [photo, setPhoto] = useState('');

    return <Paper style={styles.container}>
        <TextField
            id="add-note"
            label="Add a note"
            multiline
            rows={4}
            sx={{width: '100%', position: 'sticky', top: 0}}
            variant="standard"
        />

        {!!photo && <>
            <IconButton style={{top: '45px', position: 'relative', color:'black'}} onClick={()=>setPhoto(null)}>
                <Delete />
            </IconButton>

            <img src={photo} alt={'photo'} style={{width: '100%'}}/>
        </>}


        <AppBar position="fixed" color="primary"
                sx={{top: 'auto', bottom: window.innerHeight - parseInt(viewport.maxHeight)}}>
            <Toolbar>
                <IconButton color="inherit" aria-label="open camera">
                    <CameraButton onSave={(p: string) => setPhoto(p)}/>
                </IconButton>
            </Toolbar>
        </AppBar>
    </Paper>
}
