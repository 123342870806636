import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MessageCard from "./MessageCard";

export default function MessageList({messages}) {

    return (
        <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
            {
                messages.map((message, id) => (
                    <React.Fragment key={id}>
                        <MessageCard message={message}/>
                        <Divider component="li"/>
                    </React.Fragment>
                ))
            }
        </List>
    )
}
