import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from "../componants/Copywrite";
import {useEventCallback} from "rxjs-hooks";
import {useEffect, useState} from "react";
import {useAuth} from "./AuthProvider";
import useLocalStorage from "../hooks/useLocalStorage";
import {useLocation, useNavigate} from "react-router";
import {colors} from "@mui/material";
import {Observable} from "rxjs/Observable";
import {sendVerificationCode} from "../services/rpcs";
import QrButton from "../componants/qr-scanner/QrButton";
import {useSearchParams} from "react-router-dom";

export default function Login() {

    const [phone, setPhone] = useLocalStorage<string>('phone', '');
    const [code, setCode] = useState('');
    const [showCodeInput, setShowCode] = useState(false);
    const [disabled, disableButton] = useState(false);
    const [codeError, setCodeError] = useState('');

    const {signIn} = useAuth();

    const location = useLocation();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    let redirect = searchParams.get("redirect");

    useEffect(() => {
        location.state?.from?.pathname && setSearchParams({redirect: location.state?.from?.pathname})
    },[]);


    const handleCodeError = e => {
        setCodeError('Are you sure that is the right code?  Please try again.');
        disableButton(false);
    };

    const [getCode] = useEventCallback(event$ => event$
        .do(() => {
            setCodeError('');
            disableButton(true)
        })
        .switchMap((phone) => sendVerificationCode(phone)
            .catch(e => {
                setCodeError('There was an error with your phone number.  Please call the office.')
                disableButton(false);
                return Observable.empty();
            })
            .do(() => setShowCode(true))
            .do(() => disableButton(false))
        )
    );

    const handleSubmitPhone = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        getCode(phone);
    };

    const handleSubmitCode = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setCodeError('');
        disableButton(true);
        signIn(phone as any as string,
            code,
            (t) => {
                // @ts-ignore
                console.log('signed in', redirect);
                // @ts-ignore
                navigate(redirect || '/', {replace: true});
                disableButton(false);
            },
            handleCodeError);
    };

    useEffect(() => {
        if ('virtualKeyboard' in navigator) {
            // @ts-ignore
            navigator.virtualKeyboard.overlaysContent = true;

            // @ts-ignore
            navigator.virtualKeyboard.show();
        }
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5" sx={{verticalAlign: 'middle'}}>
                    <LockOutlinedIcon sx={{verticalAlign: 'middle', mb: '3px', mr: 1, transform: 'rotateY(180deg)'}}/>
                    Sign into DriveSafely
                </Typography>
                <Typography component="h1" variant={'body2'}>
                    Enter your phone number to get started with Drive Safely
                </Typography>

                {/* PHONE NUMBER*/}
                {!showCodeInput &&
                <Box component="form" noValidate onSubmit={handleSubmitPhone} sx={{mt: 3, width: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <QrButton color={'primary'} size={'large'}/>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                autoComplete="phone-number"
                                name="phone"
                                required
                                fullWidth
                                id="phone"
                                label="Phone Number"
                                autoFocus
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                helperText={<div style={{color: colors.red.A700}}>{codeError}</div>}
                                color={codeError ? 'error' : 'primary'}
                                inputProps={{inputMode: "tel"}}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        disabled={disabled}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Continue
                    </Button>
                </Box>}


                {/* CODE*/}
                {showCodeInput &&
                <Box component="form" noValidate onSubmit={handleSubmitCode} sx={{mt: 3, width: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="code"
                                name="code"
                                required
                                fullWidth
                                id="code"
                                label="Enter code"
                                autoFocus
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                helperText={<div style={{color: colors.red.A700}}>{codeError}</div>}
                                color={codeError ? 'error' : 'primary'}
                                inputProps={{pattern: "[0-9]*", inputMode: "numeric"}}
                                aria-label="Enter the 6 Digits code you received by SMS"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        disabled={disabled}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign In
                    </Button>
                </Box>}
            </Box>
            <Copyright sx={{mt: 5}}/>
        </Container>
    );
}
