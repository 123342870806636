import * as React from 'react';
import {Alert, Box, Typography} from "@mui/material";
import {useObservable} from "rxjs-hooks";
import {sharedVehicleStatus} from "../services/rpcs";
import QrButton from "./qr-scanner/QrButton";
import CheckOutButton from "./CheckOutButton";

export default function AssetStatus() {

    const asset = useObservable(() => sharedVehicleStatus, []);

    return (
        <Box sx={{width: '100%'}}>
            {asset.length > 0 && <>
                <Typography variant={'body1'}>You are the driver
                    of {asset?.[0]?.asset_name} [{asset?.[0]?.start_time}]</Typography>
                <CheckOutButton sx={{mt: 3, mb: 2}} fullWidth/>
            </>}

            {asset.length < 1 && <Alert color={'info'} icon={<QrButton color={'info'} size={'small'}/>}>
                You are not clocked in.
            </Alert>}
        </Box>
    )
}
