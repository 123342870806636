import React, {useState} from 'react';
import CameraIcon from "@mui/icons-material/CameraAlt";
import IconButton from "@mui/material/IconButton";
import {IconButtonProps} from "@mui/material/IconButton/IconButton";
import BottomDrawer from "../BottomDrawer";
import Camera from "./Camera";
import {Button} from "@mui/material";

interface Props extends IconButtonProps {
    onSave?: (photo:string) => void
}

export default function CameraButton(props: Props) {

    const [showCamera, setShowCamera] = useState(false);
    const toggleDrawer = (newOpen: boolean) => () => setShowCamera(newOpen);

    const [photo, setPhoto] = useState();

    const handleSave = (p) => {
        props.onSave(photo);
        console.log(photo);
        setShowCamera(false);
    }


    return (
        <>
            <IconButton
                aria-label="camera"
                color="inherit"
                component="span"
                onClick={() => setShowCamera(true)}
                {...props}
            >
                <CameraIcon/>
            </IconButton>

            <BottomDrawer
                element={<Camera onChange={setPhoto}/>}
                open={showCamera}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                rightElement={<Button disabled={!photo} onClick={handleSave}>Save</Button>}
            />
        </>
    )
}
