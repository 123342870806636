import React, {useState} from 'react';
import NoteIcon from '@mui/icons-material/NoteAdd';
import IconButton from "@mui/material/IconButton";
import {IconButtonProps} from "@mui/material/IconButton/IconButton";
import BottomDrawer from "../BottomDrawer";
import AddNote from "./AddNote";


export default function NoteButton(props: IconButtonProps) {

    const [showNote, setShowNote] = useState(false);
    const toggleNoteDrawer = (newOpen: boolean) => () => setShowNote(newOpen);



    return (
        <>
            <IconButton
                aria-label="add note"
                color="inherit"
                component="span"
                onClick={() => setShowNote(true)}
                {...props}
            >
                <NoteIcon/>
            </IconButton>

            <BottomDrawer
                element={<AddNote/>}
                open={showNote}
                onClose={toggleNoteDrawer(false)}
                onOpen={toggleNoteDrawer(true)}
                swipeToOpen={true}
            />
        </>
    )
}
