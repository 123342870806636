import React, {useState} from 'react';
import QrCodeIcon from "@mui/icons-material/QrCodeScanner";
import IconButton from "@mui/material/IconButton";
import {ScanCode} from "./ScanCode";
import {IconButtonProps} from "@mui/material/IconButton/IconButton";
import BottomDrawer from "../BottomDrawer";

export default function QrButton(props: IconButtonProps) {

    const [showScanner, setShowScanner] = useState(false);
    const toggleDrawer = (newOpen: boolean) => () => setShowScanner(newOpen);

    return (
        <>
            <IconButton
                aria-label="scan qr code"
                color="inherit"
                component="span"
                onClick={() => setShowScanner(true)}
                {...props}
            >
                <QrCodeIcon/>
            </IconButton>

            <BottomDrawer
                element={<ScanCode/>}
                open={showScanner}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            />
        </>

    )
}
