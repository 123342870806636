import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import QrButton from "./qr-scanner/QrButton";
import NoteButton from "./notes/NoteButton";


export default function MainSpeedDial() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Box sx={{height: 260, transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: 0, right: 38}}>
                <SpeedDial
                    ariaLabel="SpeedDial"
                    icon={<SpeedDialIcon/>}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                >
                    <SpeedDialAction
                        key={'Add Note or Photo'}
                        icon={<NoteButton/>}
                        tooltipTitle={'Add Note or Photo'}
                        onClick={handleClose}
                    />
                    <SpeedDialAction
                        key={'Scan QR Code'}
                        icon={<QrButton/>}
                        tooltipTitle={'Scan QR Code'}
                        onClick={handleClose}
                    />

                </SpeedDial>
            </Box>
        </>
    );
}
