import Button from "@mui/material/Button";
import * as React from "react";
import {useObservable} from "rxjs-hooks";
import {ChecklistType} from "../react-app-env";
import {sharedCheckList} from "../services/rpcs";
import {useState} from "react";
import useHandleCheckOut from "../hooks/useHandleCheckOut";

import {useNavigate} from "react-router";

export default function NewTripButton({id}) {

    const lists = useObservable<ChecklistType[] | []>(() => sharedCheckList, []);

    const [disabled, setDisabled] = useState(false);

    const [handleCheckOut] = useHandleCheckOut(
        () => setDisabled(true),
        () => {
            setDisabled(false);
            window.location.reload();
        },
        () => setDisabled(false)
    );

    if (lists.length > 0) {
        return null;
    }

    return (
        <Button variant={'contained'}
                color={'warning'}
                onClick={handleCheckOut}
                fullWidth
                disabled={disabled}
        >Start new trip</Button>
    )
}
