import * as Thruway from 'thruway.js';
import {authSubject} from "../authentication/AuthProvider";

// const url = 'ws://localhost:9983/ws';
const url = `wss://${window.location.hostname}/ws`;

export const wamp = new Thruway.Client(
    url,
    'drive',
    {'authmethods': ['drive_jwt']}
);

wamp.onChallenge((challenge) => challenge
        .switchMap(c => authSubject)
        .do(v => console.log(v))
        .pluck('token')
);

wamp.topic('test').subscribe();
