import * as React from 'react';
import {useEventCallback} from "rxjs-hooks";
import {MenuItem} from "@mui/material";
import {dismissList} from "../services/rpcs";
import {useState} from "react";
import {Observable} from "rxjs/Observable";
import {ChecklistType} from "../react-app-env";

type  MyProps = { children: any, list: ChecklistType, onClick?: (e: any) => any }

export default function DismissMenuItem(props) {

    const {children, list, onClick = (e) => e}: MyProps = props;
    const [disabled, setDisabled] = useState(false);

    const [handleDismiss] = useEventCallback(event$ =>
            event$
                .do(() => setDisabled(true))
                .switchMap(([id, e]) =>
                    dismissList(id)
                        .catch(e => {
                            setDisabled(false);
                            return Observable.empty()
                        })
                        .do(() => onClick(e))
                )
                .do(() => setDisabled(false))

        , null
    );

    return (
        <MenuItem {...props} disabled={disabled} onClick={e => handleDismiss([list.id, e])}>{children}</MenuItem>
    )
}
