import {useEventCallback} from "rxjs-hooks";
import {clockOut} from "../services/rpcs";
import {Observable} from "rxjs/Observable";

export default function useHandleCheckOut(onStart, onComplete, onError) {

    // @todo send geolocation when clocking out

    return useEventCallback((e) => e
            .do(onStart)
            .switchMapTo(clockOut()
                .take(1)
                .catch(e => {
                    onError(e);
                    //@todo need better error handling
                    return Observable.empty();
                })
                .delay(500)
                .do(onComplete)
            )

        , '');
}
