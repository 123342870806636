import axios, {AxiosResponse} from "axios";

const http = axios.create({
    // baseURL: "http://localhost:8080",
    // headers: {
    //     "Content-type": "application/json"
    // }
});

export default http;

export const fileUpload = (url, file: File, onUploadProgress): Promise<AxiosResponse> => {

    return http.put(url, file, {
        headers: {"Content-Type": file.type},
        onUploadProgress,
    });
};
