import React, {useState} from 'react';
import {Story} from '@storybook/react';
import {ViewFinder} from './ViewFinder';
import {QrReader, QrReaderProps} from "react-qr-reader";
import {useNavigate} from "react-router";
import ClockInInput from "../ClockInInput";

const styles = {
    container: {
        width: '400px',
        margin: 'auto',
    },
};

const Template: Story<QrReaderProps> = (args) => {
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    const config = {
        ViewFinder,
        videoId: 'video',
        scanDelay: 500,
        constraints: {
            facingMode: 'environment',
        },
        ...args
    }

    return (
        <div style={styles.container}>
            <QrReader
                {...config}
                onResult={(result, error) => {
                    if (result) {
                        setData(result);
                        const canVibrate = window.navigator.vibrate
                        if (canVibrate) window.navigator.vibrate([100, 20])

                        const url = isValidHttpUrl(result?.getText());

                        // Only auto navigate if the link is for the same host
                        if (url && url.hostname === window.location.hostname) {
                            navigate(url.pathname);
                        }

                        // result.getResultPoints()
                    }

                    if (error) {
                        setError(error.message);
                    }
                }}
            />
            <ClockInInput/>
            <p>The value is: {data?.text}</p>
            <p>The error is: {error}</p>
            {/*<Box points={data?.getResultPoints()}/>*/}
        </div>
    );
};

export const ScanCode = Template.bind({});

export default {
    title: 'Browser QR Reader',
    component: QrReader,
};

export function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url;
}
