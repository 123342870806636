import React, {useMemo, useState} from "react";
import ListItem from "@mui/material/ListItem";
import {ListItemAvatar, ListItemButton, ListItemIcon} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {useObservable} from "rxjs-hooks";
import {ChecklistType} from "../../react-app-env";
import {sharedCheckList} from "../../services/rpcs";
import {ListAlt} from "@mui/icons-material";
import BottomDrawer from "../BottomDrawer";
import {CheckList, ChecklistMenu} from "../CheckLists";
import AssetStatus from "../AssetStatus";

export default function MessageCard({message}) {

    switch (message.type) {
        case 'message':
            return <RegularCard message={message}/>
        case 'checklist':
            return <ChecklistCard message={message}/>
        case 'time-clock':
            return <TimeClockCard message={message}/>
        default:
            return <DebugCard message={message}/>
    }
}

function RegularCard({message}) {
    return <ListItem alignItems="flex-start">
        <ListItemIcon>
            <Avatar alt="Remy Sharp" src={message.image}/>
        </ListItemIcon>
        <ListItemText
            primary={message.title}
            secondary={
                <React.Fragment>
                    <Typography
                        sx={{display: 'inline'}}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        Ali Connors
                    </Typography>
                    {message.text}
                </React.Fragment>
            }
        />
    </ListItem>
}

function DebugCard({message}) {
    return <ListItem alignItems="flex-start">
        {JSON.stringify(message)}
    </ListItem>
}


function ChecklistCard({message}) {

    // @todo, need an RPC to get individual Check Lists
    const list = useObservable<ChecklistType[] | []>(() => sharedCheckList, [])[0];

    const completed = useMemo(() => !list?.checklist_lines?.find(i => !i.checked), [list?.checklist_lines]);
    const remaining = useMemo(() => list?.checklist_lines?.filter(i => !i.checked).length, [list?.checklist_lines]);

    const [showDrawer, setShowDrawer] = useState(false);
    const toggleDrawer = (newOpen: boolean) => () => setShowDrawer(newOpen);

    return (
        <>
            <ListItem
                alignItems="flex-start"
                secondaryAction={<ChecklistMenu list={list}/>}
                sx={{bgcolor: remaining > 0 ? 'rgba(229,130,130,0.59)' : 'inherit'}}

            >
                <ListItemButton onClick={() => setShowDrawer(true)} dense sx={{p: 0, paddingRight: 0}}>
                    <ListItemIcon>
                        <ListAlt/>
                    </ListItemIcon>
                    <ListItemText
                        primary={completed ? 'Checklist has been completed' : `There are ${remaining} unfinished items`}
                        // secondary={<React.Fragment>
                        //     <Typography
                        //         sx={{display: 'inline'}}
                        //         component="span"
                        //         variant="body2"
                        //         color="text.primary"
                        //     >
                        //         Ali Connors
                        //     </Typography>
                        //     {message.text}
                        // </React.Fragment>}
                    />
                </ListItemButton>
            </ListItem>

            <BottomDrawer
                element={<CheckList list={list}/>}
                open={showDrawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                closeLabel={'Close'}
            />
        </>
    )
}

function TimeClockCard({message}) {
    return <ListItem alignItems="flex-start">
        <AssetStatus/>
    </ListItem>
}
